<template>
  <div>
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.mobile"
      @click="drawer = !drawer"
      color="teal lighten-1"
      large
    ></v-app-bar-nav-icon>
    <v-navigation-drawer
      app
      v-model="drawer"
      overlay-color="teal"
      :right="$vuetify.breakpoint.mobile ? true : false"
      @input="onNavInput"
    >
      <v-list-item class="teal lighten-1">
        <v-list-item-content>
          <!-- <v-list-item-title>Intmedius</v-list-item-title> -->
          <img src="../assets/intmedius.png" alt="" class="intmedius-img" />
        </v-list-item-content>
      </v-list-item>
      <v-divider> </v-divider>

      <v-list nav>
        <v-list-item-group color="teal lighten-1">
          <v-btn
            rounded
            class="mx-auto my-5"
            color="teal lighten-1"
            dark
            large
            to="/appointments"
          >
            <v-icon left>mdi-clipboard-plus-outline</v-icon>

            <span>Novi pregled</span>
          </v-btn>
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-doctor</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Doktori</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="patients">
            <v-list-item-icon>
              <v-icon>mdi-account-injury</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Pacijenti</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="reports">
            <v-list-item-icon>
              <v-icon>mdi-chart-box</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Izvestaji</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <v-btn block>
          <span>Izloguj me</span>
          <v-icon right>mdi-exit-to-app</v-icon>
        </v-btn>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mini: true,
    };
  },
  props: ["drawer"],
  methods: {
    onNavInput(e) {
      console.log("CAO IZ NAVA", e);
      this.$emit("drawerUpdate", e);
    },
  },
};
</script>

<style></style>
