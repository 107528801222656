<template>
  <div class="container">
    <div class="left">
      <v-date-picker
        color="teal lighten-2"
        v-model="dates"
        range
        locale="sr-latn"
      ></v-date-picker>
      <div class="table-container">
        <v-simple-table v-if="dataLoaded">
          <thead>
            <tr>
              <th>Ime i prezime</th>
              <th>Broj pregleda</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(doc, index) in chartData.datasets[0].data" :key="index">
              <td>
                {{
                  chartData.labels[index].split(" ")[0] +
                    " " +
                    chartData.labels[index].split(" ")[1]
                }}
              </td>
              <td>{{ doc }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">Ukupno pregleda</td>
              <td class="font-weight-bold">
                {{ totalAppoints }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>
    <div class="right" v-if="dataLoaded">
      <Loader v-if="dataLoaded == false" />
      <Doughnut
        v-if="dataLoaded"
        :chart-data="chartDataC"
        css-classes="doughnut"
        ref="chartRef"
        @chart:rendered="onChartRendered"
      />
      <v-btn @click="generatePdf">Preuzmi izvestaj</v-btn>
    </div>
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
} from "chart.js";
import randomcolor from "randomcolor";
import Loader from "../components/Loader.vue";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import roboto from "../assets/Roboto-Medium-normal";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement
);
export default {
  data() {
    return {
      dates: [],
      dataLoaded: null,
      chartData: {
        labels: [],
        datasets: [{ data: [], backgroundColor: [] }],
      },
      chartRef: null,
    };
  },
  components: { Doughnut, Loader },
  methods: {
    datesPicked: () => {
      // Dohvati info ovde
      console.log(this.dates);
    },
    onChartRendered: function() {
      console.log("chart ref: ", this.chartRef);
    },
    generatePdf: async function() {
      const myData = this.chartData.datasets[0].data;
      const docLabels = this.chartData.labels;
      const pdfData = [];
      docLabels.forEach((docLabel, index) => {
        // docLabel je Ime Prezime BrPregleda zbog legende
        // tako da moramo da sklonimo broj pregleda sa kraja
        const docName = docLabel.split(" ");
        pdfData.push([index + 1, docName[0] + " " + docName[1], myData[index]]);
      });
      const pdf = new jsPDF();
      pdf.addFileToVFS("Roboto-Medium.ttf", roboto);
      pdf.addFont("Roboto-Medium.ttf", "Roboto-Medium", "normal");
      pdf.setFont("Roboto-Medium");
      pdf.text(
        `Intmedius izveštaj za period: ${this.dates[0]} - ${this.dates[1]}`,
        100,
        10,
        {
          align: "center",
        }
      );
      autoTable(pdf, {
        head: [["Br.", "Ime i prezime", "Broj pregleda"]],
        body: pdfData,
        foot: [["Ukupno", "", this.totalAppoints]],
        styles: { font: "Roboto-Medium" },
      });

      // Add chart image to PDF
      const chartEl = document.getElementById("doughnut-chart");
      const imgData = chartEl.toDataURL("image/png");
      pdf.addImage(imgData, "png", 50, 100, 100, 100);

      // Download the PDF
      pdf.save(`Intmedius izvestaj ${this.dates[0]} - ${this.dates[1]}`);
    },
  },
  mounted: async function() {
    const login = await this.$http.auth.login("peka@gmail.com", "peka123");
    console.log(login);
    const a = await this.$http.patients.search("pera");
    console.log(a.data);
  },
  computed: {
    chartDataC() {
      return this.chartData;
    },
    totalAppoints() {
      return this.chartData.datasets[0].data.reduce((prev, cur) => prev + cur);
    },
  },
  watch: {
    dates(newDateRange, oldDateRange) {
      if (
        !newDateRange[1] ||
        (newDateRange[1] == oldDateRange[1] &&
          newDateRange[0] == oldDateRange[0])
      ) {
        return;
      }
      this.dataLoaded = false;
      console.log(newDateRange[0], newDateRange[1]);
      fetch(
        `http://localhost:3001/v1/doctors/reports?startDate=${newDateRange[0]}&endDate=${newDateRange[1]}`
      )
        .then((res) => res.json())
        .then((res) => {
          const hues = ["red", "green", "blue", "yellow", "purple", "yellow"];
          let index = 0;
          this.chartData.datasets[0].data = [];
          this.chartData.datasets[0].backgroundColor = [];
          this.chartData.labels = [];
          res[0].forEach((doc) => {
            // console.log(doc);
            this.chartData.datasets[0].data.push(doc["Broj pregleda"]);
            console.log(hues[index]);
            this.chartData.datasets[0].backgroundColor.push(
              randomcolor({ hue: hues[index++], luminosity: "dark" }) //{ hue: hues[index++], luminosity: "dark" }
            );
            if (index > hues.length) {
              index = 1;
            }
            this.chartData.labels.push(
              doc["Ime"] + " " + doc["Prezime"] + " " + doc["Broj pregleda"]
            );
          });
          this.dataLoaded = true;
        });
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}
.left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 50%;
  padding-left: 30px;
}
.table-container {
  width: 100%;
}
.doughnut {
  width: 60%;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
