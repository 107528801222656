<template>
  <div class="patient-info-container" v-if="patient">
    <div class="my-info">
      <v-card class="small-info">
        <div class="icon-container">
          <v-icon color="teal ligthen-1" class="my-icon"
            >mdi-application-edit-outline</v-icon
          >
        </div>

        <v-card-title>Dijagnoza</v-card-title>

        <p class="subtitle-text">{{ currentAppointment.diagnosis }}</p>
      </v-card>
      <v-card class="small-info">
        <div class="icon-container">
          <v-icon color="teal ligthen-1" class="my-icon">mdi-pill</v-icon>
        </div>
        <v-card-title>Terapija</v-card-title>
        <p class="subtitle-text">{{ currentAppointment.therapy }}</p>
      </v-card>
      <v-card class="small-info">
        <div class="icon-container">
          <v-icon color="teal ligthen-1" class="my-icon"
            >mdi-clipboard-text-clock-outline</v-icon
          >
        </div>
        <v-card-title>Anamneza</v-card-title>
        <p class="subtitle-text">{{ currentAppointment.anamnesis }}</p>
      </v-card>
      <v-card class="small-info">
        <div class="icon-container">
          <v-icon color="teal ligthen-1" class="my-icon"
            >mdi-file-find-outline</v-icon
          >
        </div>
        <v-card-title>Objektivni Nalaz</v-card-title>
        <p class="subtitle-text">{{ currentAppointment.obj_findings }}</p>
      </v-card>
      <v-card class="small-info">
        <div class="icon-container">
          <v-icon color="teal ligthen-1" class="my-icon"
            >mdi-alert-outline</v-icon
          >
        </div>
        <v-card-title>Savet i Kontrola</v-card-title>
        <p class="subtitle-text">{{ currentAppointment.advice }}</p>
      </v-card>
      <v-card class="small-info" @click="showPhotoViewer">
        <div class="icon-container">
          <v-icon color="teal ligthen-1" class="my-icon">mdi-image</v-icon>
        </div>
        <v-card-title>Slike</v-card-title>
        <p class="subtitle-text">{{ `Broj slika: ${this.images.length}` }}</p>
      </v-card>
      <!-- <v-card class="small-info">
        <div class="icon-container">
          <v-icon color="teal ligthen-1" class="my-icon">mdi-image</v-icon>
        </div>
        <v-card-title>QR Kod</v-card-title>
        <qrcode-vue
          :value="`${origin}/appointments/${currentAppointment.id}`"
        />
      </v-card> -->
    </div>
    <div class="profile">
      <v-card class="my-card profile-card">
        <div class="icon-container">
          <v-icon color="teal ligthen-1" class="my-icon">mdi-account</v-icon>
        </div>
        <v-card-title>{{
          `Profil Pacijenta - ${patient.firstname} ${patient.lastname}`
        }}</v-card-title>
        <!-- <v-card-title>Ime Prezime</v-card-title> -->
        <div class="profile-info-container">
          <div class="profile-info">
            <v-icon>mdi-cake</v-icon>
            <div class="info-stack">
              <span class="info-title">Datum rodjenja</span>
              <span class="info-subtitle">{{
                formatDate(patient.birthdate)
              }}</span>
            </div>
          </div>
          <div class="profile-info">
            <v-icon>mdi-email</v-icon>
            <div class="info-stack">
              <span class="info-title">Email adresa</span>
              <span class="info-subtitle">{{ patient.email }}</span>
            </div>
          </div>
          <div class="profile-info">
            <v-icon>mdi-phone</v-icon>
            <div class="info-stack">
              <span class="info-title">Broj telefona</span>
              <span class="info-subtitle">{{ patient.phone }}</span>
            </div>
          </div>
          <div class="profile-info">
            <v-icon>mdi-qrcode-scan</v-icon>
            <div class="info-stack">
              <span class="info-title">QR kod</span>
              <!-- <span class="info-subtitle">{{ patient.phone }}</span> -->
              <qrcode-vue
                :value="`${origin}/appointments/${currentAppointment.id}`"
              />
            </div>
          </div>
        </div>
        <div class="appointments">
          <h3 class="appointments-title">Prethodni pregledi</h3>
          <div class="appointments-container">
            <div
              class="profile-info appointment"
              :class="currentIndex == ind ? 'active' : ''"
              v-for="(appoint, ind) in lastAppointments"
              :key="ind"
              @click="setAppointment(appoint, ind)"
            >
              <div class="icons-container">
                <v-icon color="">mdi-face-recognition</v-icon>
                <div class="info-stack">
                  <span class="info-subtitle">{{ appoint.treatments }}</span>
                  <span class="info-title">{{
                    `Dr. ${appoint.updated_by} | ${formatDate(appoint.date)}`
                  }}</span>
                </div>
              </div>

              <v-icon>mdi-chevron-right-circle-outline</v-icon>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
  components: { QrcodeVue },
  props: ["patient"],
  mounted() {
    // console.log("MOUNTED INFO");
    // if (this.patient.id) {
    //   const { data } = this.$http.appointments.getLatestForPatient(
    //     this.patient.id,
    //     3
    //   );
    //   console.log("cao iz infa", data);
    // }
    console.log("CAO RUTA", window.location.origin);
  },
  data() {
    return {
      currentAppointment: {},
      currentIndex: 0,
      lastAppointments: [],
      images: [
        "https://picsum.photos/200/200",
        "https://picsum.photos/300/200",
        "https://picsum.photos/250/200",
      ],
      origin: window.location.origin,
    };
  },
  methods: {
    initedPhotoViewer(viewer) {
      this.$viewer = viewer;
    },
    showPhotoViewer() {
      // this.$viewer.show();
      this.$viewerApi({ images: this.images });
    },
    formatDate(date) {
      const parts = date.split("T")[0].split("-");
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    },
    setAppointment(appoint, ind) {
      console.log("CLICKED APPOINT", appoint);
      this.currentIndex = ind;
      this.currentAppointment = appoint;
    },
  },
  watch: {
    async patient(newPatient) {
      this.currentIndex = 0;
      // this.currentAppointment = newPatient.appointment;
      console.log("NEW");
      console.log(newPatient);
      const { data } = await this.$http.appointments.getLatestForPatient(
        newPatient.id,
        3
      );
      this.lastAppointments = data.appointments;
      this.currentAppointment = this.lastAppointments[0];
      console.log("cao iz infa", data);
    },
    async currentAppointment(newAppoint) {
      const { data } = await this.$http.images.getImagesForAppointment(
        newAppoint.id
      );
      this.images = data.map((obj) => {
        return obj.imagepath;
      });
    },
  },
};
</script>

<style scoped>
.my-icon {
  /* background-color: rgb(179, 230, 230); */
  background-color: #e5f5f3;
  border-radius: 50%;
  padding: 10px;
}

.icon-container {
  padding-top: 16px;
  padding-left: 16px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.my-card {
  padding: 16px;
}

.patient-info-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.my-info {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.small-info {
  width: 48%;
}

.profile {
  width: 100%;
  height: 100%;
}

.profile-card {
  height: 100%;
}

.profile-info {
  display: flex;
  gap: 5px;
  padding: 16px;
}

.info-stack {
  display: flex;
  flex-direction: column;
  padding: 16px;
  justify-content: center;
}

.info-title {
  color: grey;
}

.info-subtitle {
  font-weight: bold;
}

.profile-info-container {
  display: flex;
  flex-wrap: wrap;
}

.appointments {
  margin: 16px;
}

.appointment {
  cursor: pointer;
  justify-content: space-between;
  transition: background-color 0.1s ease-in-out;
  padding: 0px 20px;
  border-radius: 5px;
}

.appointment:hover {
  background-color: #e5f5f3;
}

.appointment.active {
  background-color: #e5f5f3;
}

.icons-container {
  display: flex;
}

.subtitle-text {
  padding: 16px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  margin: 0;
}
</style>
