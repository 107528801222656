<template>
  <v-card
    class="mx-auto"
    :class="active ? 'active' : ''"
    :dark="active ? true : false"
    shaped
    max-width="350"
  >
    <v-card-title align="center">
      {{ patient.firstname }} {{ patient.lastname }}
    </v-card-title>
    <v-card-subtitle>Poslednji pregled: 1.1.2011</v-card-subtitle>
    <v-card-text>
      <div class="chip-container">
        <v-chip>
          <v-icon left>mdi-cake-variant</v-icon
          >{{
            Math.floor(
              (new Date().getTime() - new Date(patient.birthdate).getTime()) /
                31536000000
            )
          }}</v-chip
        >
        <v-chip> <v-icon left>mdi-email</v-icon>{{ patient.email }}</v-chip>
        <v-chip> <v-icon left>mdi-phone</v-icon>{{ patient.phone }}</v-chip>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-btn
        text
        :color="active ? 'white' : 'teal lighten-1'"
        @click="openAppointmentOverlay"
        >Novi pregled</v-btn
      >
      <v-btn text :color="active ? 'white' : 'teal lighten-1'"
        >Profil pacijenta</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn icon @click="show = !show">
        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>
    <!-- Dropdown for last three appointments -->
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <!-- Info o poslednjem pregledu: datum, lokacija, vrsta tretmana, doktor -->
        <v-card-text>
          <!-- I'm a thing. But, like most politicians, he promised more than he
          could deliver. You won't have time for sleeping, soldier, not with all
          the bed making you'll be doing. Then we'll go with that data file!
          Hey, you add a one and two zeros to that or we walk! You're going to
          do his laundry? I've got to find a way to escape. -->
          <h3>Poslednja 3 pregleda</h3>
          <!-- <v-chip> <v-icon left>mdi-calendar</v-icon>1.1.2021</v-chip>
          <v-chip><v-icon left>mdi-map-marker</v-icon>Prote Mateje</v-chip>
          <v-chip><v-icon left>mdi-notebook</v-icon>Laser</v-chip>
          <v-chip><v-icon left>mdi-doctor</v-icon>Doktor Doktoric</v-chip> -->
          <ul>
            <li>
              <v-icon>mdi-calendar</v-icon>1.1.2021 |
              <v-icon>mdi-map-marker</v-icon>Prote Mateje |
              <v-icon>mdi-notebook</v-icon>Laser |
              <v-icon>mdi-doctor</v-icon>Doktor Doktoric
            </li>

            <li>
              <v-icon>mdi-calendar</v-icon>1.1.2021 |
              <v-icon>mdi-map-marker</v-icon>Prote Mateje |
              <v-icon>mdi-notebook</v-icon>Laser |
              <v-icon>mdi-doctor</v-icon>Doktor Doktoric
            </li>
            <li>
              <v-icon>mdi-calendar</v-icon>1.1.2021 |
              <v-icon>mdi-map-marker</v-icon>Prote Mateje |
              <v-icon>mdi-notebook</v-icon>Laser |
              <v-icon>mdi-doctor</v-icon>Doktor Doktoric
            </li>
          </ul>
          <!-- <v-list shaped>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon>mdi-calendar</v-icon>1.1.2021 |
                  <v-icon>mdi-map-marker</v-icon>Prote Mateje |
                  <v-icon>mdi-notebook</v-icon>Laser |
                  <v-icon>mdi-doctor</v-icon>Doktor Doktoric
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list> -->
        </v-card-text>
      </div>
    </v-expand-transition>
    <v-overlay :value="showOverlay" class="my-overlay">
      <v-card light class="appoint-wrapper">
        <appointment-form @cancel="closeOverlay" :patient="patient" />
      </v-card>
    </v-overlay>
  </v-card>
</template>

<script>
import AppointmentForm from "./AppointmentForm.vue";

export default {
  components: { AppointmentForm },
  data: () => ({
    //
    show: false,
    showOverlay: false,
  }),
  props: ["patient", "active"],
  methods: {
    openAppointmentOverlay() {
      this.showOverlay = true;
    },
    closeOverlay() {
      this.showOverlay = false;
    },
  },
};
</script>

<style scoped>
.chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.my-overlay {
  /* background-color: white; */
}

.appoint-wrapper {
  padding: 30px;
  max-width: 65vw;
}

.active {
  background-color: teal;
}
</style>
