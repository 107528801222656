import patients from "./patients";
import auth from "./auth";
import location from "./location";
import appointments from "./appointments";
import images from "./images";

export default {
    patients,
    auth,
    location,
    appointments,
    images  
}