import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
});

// const http = Vue.observable({
//   hasError: false,
//   api,
//   raiseError() {
//     http.hasError = true;
//   },
// });

api.interceptors.response.use(
  (res) => {
    console.log("cao iz dobrog inter");
    return res;
  },
  (err) => {
    // http.raiseError();
    // api.errors = errors;
    return err;
    // return Promise.reject(err);
  }
);

export default api;
