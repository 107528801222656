<template>
  <div class="flex">
    <v-overlay :value="overlay">
      <v-card v-click-outside="closeOverlay">
        <div class="overlay pa-5">
          <h1>Zakazi novi pregled</h1>
          <div class="time-date-container">
            <v-date-picker
              class="lol"
              v-model="date"
              :first-day-of-week="1"
              locale="sr-latn"
            ></v-date-picker>
            <v-time-picker format="24hr"></v-time-picker>
          </div>

          <h2>Pacijent</h2>
          <!-- <v-autocomplete
            v-model="patientSearch"
            :loading="patientSearchLoading"
            :items="patients"
            :search-input.sync="searchPatients"
          >
          </v-autocomplete> -->
        </div>
      </v-card>
    </v-overlay>
    <div class="left">
      <h1 class="my-title">Zakazivanje novog pregleda</h1>
      <div class="calendar-container">
        <v-autocomplete
          v-model="selectedDoctor"
          @change="selectDoctor"
          :items="doctors"
        ></v-autocomplete>
        <v-calendar
          ref="calendar"
          category-show-all
          :weekdays="weekday"
          type="category"
          :events="events"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :categories="categories"
          @click:time="createEvent"
          locale="sr-latn"
        ></v-calendar>
      </div>

      <!-- <PatientCard name="Marko"></PatientCard> -->
    </div>
    <patient-search></patient-search>
  </div>
</template>

<script>
// @ is an alias to /src
// import PatientCard from "../components/PatientCard.vue";
import PatientSearch from "../components/PatientSearch.vue";

export default {
  name: "Home",
  components: { PatientSearch },
  data() {
    return {
      type: "day",
      mode: "stack",
      page: 1,
      numPages: 1,
      weekday: [
        {
          text: "Pon, Uto, Sre, Cet, Pet, Sub, Ned",
          value: [1, 2, 3, 4, 5, 6, 7],
        },
      ],
      events: [
        {
          name: "Pregled",
          start: new Date(),
          end: new Date(),
          category: "Doktor 1",
          timed: true,
        },
        {
          name: "Pregled drugog doktora",
          start: new Date("22 November, 2021 13:00:00"),
          end: new Date("22 November, 2021 13:30:00"),
          category: "Doktor 2",
          timed: true,
        },
      ],
      categories: ["Doktor 1", "Doktor 2"],
      createdEvent: null,
      doctors: ["Doktor 1", "Doktor 2", "Perić", "Peric"],
      selectedDoctor: null,
      overlay: false,
      date: null,
      patientSearchLoading: false,
      // patientSearch: null,
      patients: [],
      // searchPatients: null,
    };
  },
  methods: {
    createEvent(tms) {
      this.overlay = true;
      console.log(tms);
      this.createdEvent = {
        name: `Pregled #${this.events.length}`,
        start: this.toTime(tms),
        // end: this.toTime(tms),
        timed: true,
        category: this.selectedDoctor,
      };
      this.events.push(this.createdEvent);
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    selectDoctor() {
      this.categories = [this.selectedDoctor];
    },
    closeOverlay() {
      this.overlay = false;
      console.log("clicked overlay");
    },
  },
  watch: {
    // searchPatients(val) {
    //   // if (this.patients.length > 0) return;
    //   if (val == "" || !val) return;
    //   console.log(val);
    //   if (this.patientSearchLoading) return;
    //   this.patientSearchLoading = true;
    //   fetch(`http://localhost:3001/v1/patients/search?search=${val}`)
    //     .then((res) => res.json())
    //     .then((res) => {
    //       console.log(res);
    //       res.forEach((patient) => {
    //         patient.text = patient.firstname + " " + patient.lastname;
    //       });
    //       this.patients = res;
    //       this.patientSearchLoading = false;
    //     });
    // },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  height: 100vh;
}

.left {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  /* margin-right: 100px; */
  padding: 10px;
  /* overflow-x: scroll; */
}

.right {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.calendar-container {
  height: 90vh;
  /* width: 100%; */
}

.overlay {
  /* height: 50%;
  width: 50%; */
  /* background-color: rgb(192, 108, 108); */
}

.time-date-container {
  display: flex;
  gap: 25px;
  justify-content: center;
  align-items: center;
}

.lol {
  height: 100%;
  padding-top: 14px;
}

.my-title {
  text-align: center;
}

@media only screen and (max-width: 1000px) {
  .flex {
    height: 50vh;
    flex-direction: column;
    justify-content: start;
    max-width: 100%;
    padding: 40px;
    height: 60vh;
    /* overflow-x: hidden; */
  }
  .left {
    height: 100%;
  }
}
</style>
