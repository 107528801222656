<template>
  <v-app>
    <div class="loggedContainer" v-if="loggedIn">
      <navbar :drawer="drawer" @drawer-update="onDrawerUpdate" />
      <v-main>
        <!-- <v-container> -->
        <router-view></router-view>
        <!-- </v-container> -->
      </v-main>
    </div>
    <div class="login" v-else>
      <div class="login-container">
        <v-form>
          <v-text-field label="Korisnicko ime" v-model="username">
          </v-text-field>
          <v-text-field
            label="Lozinka"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            :type="showPassword ? 'text' : 'password'"
            v-model="password"
            @keydown.enter="login"
            @click:append="showPassword = !showPassword"
          >
          </v-text-field>
          <v-btn @click="login">Uloguj se</v-btn>
        </v-form>
      </div>
    </div>
    <div class="alert-container">
      <v-alert
        transition="fade-transition"
        v-show="hasError"
        dismissible
        type="error"
        >Greska na serveru</v-alert
      >
    </div>
  </v-app>
</template>

<script>
// import PatientCard from "./components/PatientCard.vue";
import Navbar from "./components/Navbar.vue";

export default {
  data() {
    return {
      drawer: true,
      type: "day",
      mode: "stack",
      weekday: [
        {
          text: "Pon, Uto, Sre, Cet, Pet, Sub, Ned",
          value: [1, 2, 3, 4, 5, 6, 7],
        },
      ],
      events: [
        {
          name: "Pregled",
          start: new Date("22 November, 2021 16:00:00"),
          end: new Date("22 November, 2021 17:00:00"),
          category: "Doktor 1",
          timed: true,
        },
        {
          name: "Pregled drugog doktora",
          start: new Date("22 November, 2021 13:00:00"),
          end: new Date("22 November, 2021 13:30:00"),
          category: "Doktor 2",
          timed: true,
        },
      ],
      categories: ["Doktor 1", "Doktor 2"],
      // TODO CHANGE THIS TO FALSE
      loggedIn: true,
      username: "",
      password: "",
      showPassword: false,
      hasError: false,
    };
  },
  components: { Navbar },
  methods: {
    login: async function() {
      const res = await this.$http.auth.login(this.username, this.password);
      console.log("evo iz pozivaoca", res);
      if (res.status < 300) {
        this.loggedIn = true;
      }
    },
    onDrawerUpdate(e) {
      console.log("primio iz nava", e);
      this.drawer = e;
    },
  },
  mounted() {
    this.$root.$on("apiError", () => {
      this.hasError = true;
      setTimeout(() => {
        this.hasError = false;
      }, 3000);
    });
  },
};
</script>

<style lang="scss">
.intmedius-img {
  width: 100%;
  height: 100%;
}
.login {
  display: flex;
  width: 100%;
  height: 70vh;
  justify-content: center;
  align-items: center;
}

.teal-test {
  background-color: teal;
}

.alert-container {
  position: fixed;
  z-index: 101;
  right: 20px;
  bottom: 0px;
}
</style>
