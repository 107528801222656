<template>
  <div class="patient-page-container">
    <patient-search></patient-search>
  </div>
</template>

<script>
import PatientSearch from "../components/PatientSearch.vue";

export default {
  components: {
    PatientSearch,
  },
};
</script>

<style scoped>
.patient-page-container {
  padding: 25px;
}
</style>
