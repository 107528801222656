import http from "./main";

export default {
    getAll: async () => {
        return await http.get("/appointments");
    },
    getById: async (id) => {
        return await http.get(`/appointments/${id}`);
    },
    getLatestForPatient: async(patientId, num) => {
        return await http.get(`appointments/latest/${patientId}/${num}`);
    }
}