<template>
  <div class="flex-container">
    <h1 class="text-lg-h3 text-md-h4 my-3">Novi izveštaj</h1>
    <div class="flex-col">
      <div class="form-container">
        <div class="flex-row">
          <v-autocomplete
            v-if="!patient"
            label="Pacijent"
            outlined
            append-icon="mdi-account-injury"
            v-model="selectedPatient"
            :item-text="selectedPatient"
          ></v-autocomplete>
          <v-text-field
            v-else
            outlined
            label="Pacijent"
            v-model="selectedPatient"
            readonly
            append-icon="mdi-account-injury"
          ></v-text-field>
        </div>
        <div class="flex-row">
          <v-select
            :items="doctors"
            label="Doktor"
            outlined
            append-icon="mdi-doctor"
          ></v-select>
          <v-select
            :items="locations"
            label="Lokacija"
            outlined
            @change="handleLocationChange"
            append-icon="mdi-map-marker"
          ></v-select>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedDate"
                label="Datum"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="appointment.date"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="flex-row">
          <v-checkbox
            v-for="(treatment, index) in treatments"
            :key="index"
            v-model="treatmentCheckboxes"
            :label="treatment"
            :value="treatment"
          ></v-checkbox>
        </div>
        <div class="flex-row">
          <v-textarea
            label="Anamneza"
            outlined
            clearable
            auto-grow
            v-model="appointment.anamnesis"
          ></v-textarea>
          <v-textarea
            label="Objektivni nalaz"
            outlined
            clearable
            auto-grow
            v-model="appointment.obj_findings"
          ></v-textarea>
        </div>
        <div class="flex-row">
          <v-textarea
            label="Dijagnoza"
            outlined
            clearable
            auto-grow
            v-model="appointment.diagnosis"
          ></v-textarea>
          <v-textarea
            label="Terapija"
            outlined
            clearable
            auto-grow
            v-model="appointment.therapy"
          ></v-textarea>
        </div>
        <div class="flex-row">
          <v-textarea
            label="Savet i kontrola"
            outlined
            clearable
            no-resize
            v-model="appointment.advice"
          ></v-textarea>
        </div>
        <div class="flex-row button-row">
          <v-btn color="teal lighten-1" dark @click="handleSave">Sacuvaj</v-btn>
          <v-btn
            v-if="$vuetify.breakpoint.mobile"
            color="primary"
            @click="onUploadClick"
            >Slike</v-btn
          >
          <v-btn color="error" @click="handleCancel">Prekini</v-btn>
        </div>
      </div>
      <div class="upload-container">
        <h2 class="text-center md h4">Galerija slika</h2>

        <div
          class="drag-drop"
          @drop="handleDrop"
          @dragover="handleDragover"
          v-show="!$vuetify.breakpoint.mobile"
        >
          <p class="text-center">Ovde prevucite slike</p>
          <input
            type="file"
            multiple
            hidden
            ref="imgInput"
            @change="onImgSelected"
          />
          <v-btn color="teal lighten-1" dark @click="onUploadClick"
            >Postavi slike</v-btn
          >
        </div>
        <div class="img-container">
          <v-img
            v-for="(imgSrc, index) in imageLinks"
            :key="index"
            :src="imgSrc"
            @click="openOverlay(imgSrc)"
            class="uploaded-img"
            ><v-icon
              class="close-icon"
              @click="removeImage(index)"
              z-index="1000"
              >mdi-close-circle-outline</v-icon
            ></v-img
          >
        </div>
      </div>
    </div>
    <v-overlay :value="overlay" z-index="1000">
      <div class="overlay-container">
        <vue-photo-zoom-pro
          :high-url="focusedImgSrc"
          type="circle"
          v-if="!$vuetify.breakpoint.mobile"
        >
          <v-img class="overlay-img" :src="focusedImgSrc"
            ><v-icon
              @click="overlay = false"
              class="close-icon"
              x-large
              z-index="1000"
              >mdi-close-circle-outline</v-icon
            ></v-img
          >
        </vue-photo-zoom-pro>
        <v-img v-else class="overlay-img" :src="focusedImgSrc"
          ><v-icon
            @click="overlay = false"
            class="close-icon"
            x-large
            z-index="1000"
            >mdi-close-circle-outline</v-icon
          ></v-img
        >
      </div>
    </v-overlay>
  </div>
</template>

<script lang="ts">
import VuePhotoZoomPro from "vue-photo-zoom-pro";
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";

export default {
  components: {
    VuePhotoZoomPro,
  },
  props: ["patient"],
  data() {
    return {
      selectedPatient: "",
      doctors: ["Pera", "Zika"],
      locations: ["Novi Beograd", "Centar"],
      treatments: [],
      treatmentCheckboxes: [""],
      //date: new Date().toISOString().split("T")[0],
      menu: false,
      uploadedImages: [],
      imageLinks: [],
      focusedImgSrc: "",
      overlay: false,
      allTreatments: {},
      appointment: {
        patient: "",
        doctor: "",
        location: "",
        date: new Date().toISOString().split("T")[0],
        anamensis: "",
        obj_findings: "",
        diagnosis: "",
        therapy: "",
        advice: "",
        treatments: [],
        pictures: [],
      },
    };
  },
  async mounted() {
    if (this.patient) {
      this.selectedPatient =
        this.patient.firstname + " " + this.patient.lastname;
    }
    this.getLocations();
    const id = this.$route.params.id;
    if (id) {
      // ucitati konkretan
      console.log(id);
      const res = await this.$http.appointments.getById(id);
      if (res.data.length === 0) {
        return;
      }
      const appoint = res.data[0];
      this.appointment = appoint;
      console.log(this.appointment.date);
      this.appointment.date = appoint.updated.split("T")[0];
      console.log("APPOMT", this.appointment);
      console.log(res);
    }
  },
  computed: {
    formattedDate() {
      return this.formatDate(this.appointment.date);
    },
  },
  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    onImageUpload() {
      this.newFiles.forEach((file) => {
        this.files.push(file);
        this.imageLinks.push(URL.createObjectURL(file));
      });
    },
    onUploadClick() {
      this.$refs.imgInput.click();
    },
    onImgSelected(e) {
      console.log("WOOO");
      console.log(e);
      Object.values(e.target.files).forEach((item) => {
        console.log(item);
        this.addImageLink(item);
      });
    },
    removeImage(index) {
      this.imageLinks.splice(index, 1);
    },
    handleDrop(e) {
      e.preventDefault();
      console.log(e.dataTransfer.items[0].getAsFile());
      e.dataTransfer.items.forEach((item) => {
        this.addImageLink(item.getAsFile());
      });
    },
    handleDragover(e) {
      e.preventDefault();
    },
    addImageLink(item) {
      this.imageLinks.push(URL.createObjectURL(item));
    },
    openOverlay(src) {
      console.log("slicka");
      this.overlay = true;
      this.focusedImgSrc = src;
    },
    handleLocationChange(e) {
      console.log(this.allTreatments);
      this.treatments = this.allTreatments[e];
    },
    async getLocations() {
      const { data } = await this.$http.location.getAll();
      data.forEach((location) => {
        this.allTreatments[location.location_title] = location.treatments;
      });
      this.locations = data.map((location) => location.location_title);
    },
    handleSave() {
      console.log("saved");
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.flex-row {
  display: flex;
  justify-content: space-evenly;
  width: 90%;
  gap: 30px;
}

.flex-col {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 20px;
}

.my-title {
  /* text-align: center; */
}

.form-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.drag-drop {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 140px;
  border: 3px dashed #26a69a;
}

.img-input {
  width: 70%;
}

.uploaded-img {
  max-width: 40%;
  border-radius: 7px;
  object-fit: contain;
  cursor: pointer;
}

.img-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
}

.close-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  color: red;
}

.overlay-img {
  max-width: 40vw;
  /* max-height: 50vh; */
  object-fit: contain;
  margin: auto;
}

.overlay-container {
  object-fit: contain;
  /* max-width: 70vw; */
}

.button-row > button {
  width: 50%;
}

@media only screen and (max-width: 1000px) {
  .flex-col {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }

  .flex-row {
    flex-direction: column !important;
    gap: 0px;
  }

  .button-row {
    position: fixed;
    bottom: 10px;
    flex-direction: row !important;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 50%;
    z-index: 500;
  }

  .button-row > button {
    width: 50%;
  }

  .form-container {
    width: 90%;
  }

  .overlay-img {
    max-width: 400px;
  }

  .upload-container {
    width: 80%;
  }

  .img-container {
    flex-direction: column;
    width: 100%;
    /* min-width: 150px; */
    min-width: 200px;
  }
  .uploaded-img {
    margin: auto;
    width: 100%;
    min-width: 200px;
  }
}
</style>
