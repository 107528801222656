import api from "./main";

export default {
    login: async (email, password) => {
        // Set the headers on the api obj
        const res = await api.post("/users/signin", {email, password});
        console.log(res);
        api.defaults.headers["authorization"] = `Bearer ${res.data.token}`;
        return res;
    }
}