<template>
  <div class="my-container">
    <div class="right">
      <h1 class="my-title">Pretraga pacijenata</h1>
      <!-- <v-autocomplete
      v-model="search"
      :loading="patientSearchLoading"
      :items="patients"
      :search-input.sync="searchPatients"
    >
    </v-autocomplete> -->

      <!-- @input="searchPatients" -->
      <v-text-field
        v-model="search"
        class="patient-search-input"
        outlined
        color="teal"
        filled
        placeholder="Unesite ime/prezime pacijenta"
      ></v-text-field>
      <!-- <div v-for="(patient, index) in patients" :key="index">
        <patient-card :patient="patient"></patient-card>
      </div> -->
      <!--  -->
      <!-- <div class="patients-container" v-if="patients.length > 0"> -->

      <!-- <div v-for="index in Math.min(patients.length, 3)" :key="index">
      <patient-card :patient="patients[index * page]"></patient-card>
    </div> -->

      <div v-if="patients.length !== 0" class="patients-container">
        <div
          v-for="(patient, ind) in filteredPatients"
          :key="ind"
          @click="selectCurrentPatient(patient)"
          class="selected-patient"
        >
          <patient-card
            :patient="patient"
            :active="currentPatientId === patient.id"
          ></patient-card>
        </div>
      </div>

      <div v-else class="no-info">
        Nema rezultata za trenutnu pretragu
      </div>

      <!-- </div> -->

      <v-pagination
        v-model="page"
        :length="Math.ceil(patients.length / 3)"
        v-if="patients.length"
      ></v-pagination>
    </div>
    <div class="patient-info" v-if="currentPatient">
      <patient-info :patient="currentPatient"></patient-info>
    </div>
  </div>
</template>

<script>
import PatientCard from "./PatientCard.vue";
import PatientInfo from "./PatientInfo.vue";

export default {
  name: "PatientSearch",
  components: { PatientCard, PatientInfo },
  data() {
    return {
      patientSearchLoading: false,
      search: null,
      patients: [],
      page: 1,
      currentPatient: {},
      currentPatientId: null,
      //   searchPatients: null,
    };
  },
  mounted: function() {
    // console.log("caososocoaoas");
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }
  },
  methods: {
    async searchPatients() {
      //   if (this.patients.length > 0) return;
      if (this.search == "" || !this.search) return;
      if (this.patientSearchLoading) return;
      this.patientSearchLoading = true;
      this.$router.push({
        path: this.$route.fullPath,
        query: { search: this.search },
      });
      try {
        const res = await this.$http.patients.search(this.search);
        this.patients = res.data;
        console.log("FILTRIRAIRANO", this.filteredPatients);
        this.patientSearchLoading = false;
      } catch (err) {
        console.log(err);
        this.$root.$emit("apiError");
      }
      // console.log("hellooo");
      //   res.data.forEach((patient) => {
      //     patient.text = patient.firstname + " " + patient.lastname;
      //   });
    },
    async selectCurrentPatient(patient) {
      console.log(patient);
      this.currentPatientId = patient.id;
      // const { data } = await this.$http.appointments.getLatestForPatient(
      //   patient.id,
      //   1
      // );
      // console.log(data);
      // this.currentPatient = {};
      // patient.appointment = data.appointments[0];
      this.currentPatient = patient;
    },
  },
  watch: {
    search() {
      this.searchPatients();
    },
  },
  computed: {
    filteredPatients() {
      //return this.patients.splice(this.page - 1, 3);
      return [
        this.patients[(this.page - 1) * 3],
        this.patients[(this.page - 1) * 3 + 1],
        this.patients[(this.page - 1) * 3 + 2],
      ];
    },
  },
};
</script>

<style scoped>
.right {
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
  justify-content: flex-start;
  width: 30%;
  /* height: 80vh; */
}
.patients-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-evenly;
  width: 100%;
}

.patient-search-input {
  max-height: 10%;
}

.my-container {
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
  gap: 5%;
}

.no-info {
  margin-top: 150px;
  text-align: center;
}

.patient-info {
  width: 70%;
}

.selected-patient {
  /* background-color: teal; */
}
</style>
